import React from "react";
import Head from "next/head";
import Script from "next/script";
import { htmlToText } from "html-to-text";
import { useSelector } from "react-redux";
import ReactHtmlParser from "html-react-parser";
import { IEvent } from "@Interfaces";
import {
    SELECTORS,
    isEventFetched,
    ADDRESS_TYPE,
    EVENT_TYPE,
    MEDIA,
    getCurrencyString,
    EVENT_STATUS,
    chainShortCircuits,
    getThemeData,
} from "@Utils";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import { CustomCode } from "@Modules/customCode/CustomCode";
import { getFaviconUrl } from "repoV2/components/common/HtmlHeader/HtmlHeader.utils";
import { parseDateTime } from "@Utils/parseDateTime";
import GTMScript from "repoV2/features/Common/Analytics/modules/GoogleTagManager/modules/GTMScript/GTMScript";
import { IHTMLHeader } from "./HTMLHeader";

const HTMLHeader = ({
    isEvent = false,
    title,
    favicon,
    description,
    image,
    video,
    metaScript,
    scriptTags,
    additionalHeaders,
    customSeoParams,
}: IHTMLHeader.IProps): JSX.Element => {
    const host = useSelector(SELECTORS.host);
    const {
        hostName,
        font: { url: fontURL },
        dataHost: { profile_data: hostData },
    } = host;

    // Handling Custom Seo if added from Creator tool
    const isCustomListingSeo = !!(
        customSeoParams?.description || customSeoParams?.title
    );
    const isCustomSeo = !!(isCustomListingSeo || hostData?.seo_preview);
    const seoPreview =
        isCustomListingSeo && isCustomSeo
            ? customSeoParams
            : hostData?.seo_preview;

    const themeData = getThemeData({ host });

    const themeStyleSheet = `:root {\n${themeData
        .map(([dKey, dVal]) => `${dKey}: ${dVal}`)
        .join("; \n")}\n}`;

    const { selectedEvent, list: eventList }: IEvent.IStore = useSelector(
        SELECTORS.event
    );

    const eventData: IEvent.IEventData | null =
        isEvent && selectedEvent && isEventFetched(selectedEvent, eventList)
            ? eventList[selectedEvent]
            : null;

    const eventType = eventData ? eventData.type : -1;

    const projectDomain: string =
        process.env.NEXT_PUBLIC_PROJECT_DOMAIN || "exlyapp.com";

    const eventUrl: string = eventData
        ? `https://${hostName}.${projectDomain}/${eventData?.uuid}`
        : "";

    const startDate: string = chainShortCircuits<string>(
        [
            [
                [EVENT_TYPE.APPOINTMENTS, EVENT_TYPE.WORKSHOPS].includes(
                    eventType
                ) && eventData?.slots?.length,
                parseDateTime(eventData?.slots?.[0]?.start_time!),
            ],
            [
                [
                    EVENT_TYPE.GROUP_CLASSES,
                    EVENT_TYPE.ONE_ON_ONE_CLASSES,
                    EVENT_TYPE.ROLLING_CLASSES,
                ].includes(eventType) && eventData?.next_slot_time,
                parseDateTime(eventData?.next_slot_time!),
            ],
        ],
        "" // Is not truthy so won't render
    );

    // TODO: Detailed type declaration when structure is finalised
    interface IStructuredData {
        host: { [k: string]: any };
        event?: { [k: string]: any };
        faq?: { [k: string]: any };
    }
    const structuredData: IStructuredData = {
        host: {
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://exlyapp.com",
            name: "Exly by MyScoot",
            logo: `https://${projectDomain}/static/images/exly-logo.png`,
        },
        ...(eventData && {
            event: {
                "@context": "https://schema.org",
                "@type": "Event",
                name: title,
                ...(startDate && {
                    startDate,
                }),

                ...(![
                    EVENT_TYPE.RECORDED_CONTENT,
                    EVENT_TYPE.MERCHANDISE,
                ].includes(eventType) && {
                    eventAttendanceMode: `https://schema.org/${
                        [
                            ADDRESS_TYPE.MEET_AUTO_GENERATE,
                            ADDRESS_TYPE.EXISTING_LINK,
                        ].includes(eventData?.address_type)
                            ? "Online"
                            : "Offline"
                    }EventAttendanceMode`,
                }),
                location: {
                    "@type": "VirtualLocation",
                    url: eventUrl,
                },

                eventStatus: "https://schema.org/EventScheduled",

                image: [eventData?.cover_image || MEDIA.DEFAULT.CARD_IMAGE],
                description,
                offers: {
                    "@type": "Offer",
                    url: eventUrl,
                    price: eventData.updated_price,
                    priceCurrency: getCurrencyString(eventData?.currency),
                    availability: `https://schema.org/${
                        eventData?.status === EVENT_STATUS.LIVE
                            ? "InStock"
                            : "OutOfStock"
                    }`,
                },
                performer: {
                    "@type": "PerformingGroup",
                    name: hostData?.title || hostData?.name || "Exly",
                },
                organizer: {
                    "@type": "Organization",
                    name: "Exly by MyScoot",
                    url: "https://exlyapp.com",
                },
            },
            ...(eventData?.faqs &&
                eventData?.faqs?.length && {
                    faq: {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        mainEntity: eventData?.faqs.map((faq: IEvent.IFAQ) => ({
                            "@type": "Question",
                            name: faq.question,
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: htmlToText(faq.answer),
                            },
                        })),
                    },
                }),
        }),
    };

    const defaultSeo = (
        <>
            <title>{title || "Exly"}</title>
            <meta property="og:title" content={title || "Exly"} />
            <meta property="og:type" content="website" />
            {description && (
                <meta property="og:description" content={description} />
            )}
            {description && <meta name="description" content={description} />}
        </>
    );
    const customSeo = (
        <>
            <title>{seoPreview?.title || "Exly"}</title>
            <meta property="og:title" content={seoPreview?.title || "Exly"} />
            <meta property="og:type" content="website" />
            {seoPreview?.description && (
                <meta
                    property="og:description"
                    content={seoPreview.description}
                />
            )}
            {seoPreview?.description && (
                <meta name="description" content={seoPreview.description} />
            )}
        </>
    );

    return (
        <>
            <Head>
                {isCustomSeo ? customSeo : defaultSeo}
                <link href={fontURL} rel="stylesheet" />
                {video ? <meta property="og:video" content={video} /> : null}
                {image ? ( // Event cover image
                    <>
                        <meta
                            property="og:image"
                            // Adding itemprop is helpful for SEO
                            // eslint-disable-next-line react/no-unknown-property
                            // itemprop="image"
                            content={image}
                        />
                        <meta property="og:image:type" content="image/png" />
                    </>
                ) : null}

                <link
                    rel="icon"
                    type="image/png"
                    href={getFaviconUrl({
                        creatorSiteFaviconImgSrc: favicon,
                        hostName,
                    })}
                />

                {/* eslint-disable-next-line react/no-danger */}
                <style dangerouslySetInnerHTML={{ __html: themeStyleSheet }} />

                {additionalHeaders?.()}

                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                {/* This ensures that proper and safe parsing is done of the meta script */}
                {metaScript ? ReactHtmlParser(metaScript) : null}

                {/* NOTE: not sure why, but calling this with component syntax inside HEAD does not make it appear in the DOM */}
                {CustomCode({ position: CUSTOM_CODES_POSITION.HEAD })}
            </Head>
            {Object.values(structuredData).map((dataObj: Object) =>
                dataObj ? (
                    <Script
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(dataObj) || "{}",
                        }}
                        key={JSON.stringify(dataObj)}
                        type="application/ld+json"
                    />
                ) : null
            )}

            {/* Used added custom scripts to be loaded */}
            {(scriptTags || []).map(scriptUrl => (
                <Script
                    strategy="afterInteractive"
                    key={scriptUrl}
                    src={scriptUrl}
                />
            ))}

            <GTMScript accessToken={hostData?.custom_ga_token} />
        </>
    );
};

export default HTMLHeader;
